import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/Layout"
import HeadMeta from "../components/HeadMeta"
import PageHeader from "../components/PageHeader"
import Breadcrumb from "../components/Breadcrumb"
import BrowseList from "../components/BrowseList"

export default ({ data }) => {
  const parlEdges = data.allParliamentariansCsv.edges
  return (
    <Layout>
      <HeadMeta pageTitle={`NSW Parliamentarians`} />
      <PageHeader>NSW Parliamentarians</PageHeader>
      <Breadcrumb
        route={[
          { name: "Parliamentary Papers", href: "/" },
          { name: "NSW Parliamentarians", href: "/parliamentarians/" },
        ]}
      />
      <BrowseList
        items={parlEdges.map(parlEdge => {
          return {
            fields: {
              name: parlEdge.node.fields.name,
              slug: parlEdge.node.fields.slug,
            },
          }
        })}
        sortFunc={(a, b) => {
          const getSurname = (name) => {
            return name.replace(/\(.+\)$/, '')
              .replace(/(^\s*|\s*$)/g, '')
              .replace(/[^a-z\s]/gi, '')
              .split(' ')
              .pop()
              .toLowerCase();
          }
          const nameA = getSurname(a.fields.name);
          const nameB = getSurname(b.fields.name);
          if (nameA.toLowerCase() < nameB.toLowerCase()) {
            return -1
          }
          if (nameA.toLowerCase() > nameB.toLowerCase()) {
            return 1
          }
          return 0
        }}
      />
    </Layout>
  )
}

export const query = graphql`
  query ParliamentarianIndexQuery {
    allParliamentariansCsv(
      filter: { fields: { id: { ne: null } } }
      sort: { fields: fields___familyName }
    ) {
      edges {
        node {
          fields {
            name
            familyName
            title
            otherNames
            dateBirth
            dateDeath
            slug
            parliamentLink
          }
        }
      }
    }
  }
`
